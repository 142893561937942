import { render, staticRenderFns } from "./CasinoSlotGameHonor.vue?vue&type=template&id=3d4d0c67&scoped=true&"
import script from "./CasinoSlotGameHonor.vue?vue&type=script&lang=js&"
export * from "./CasinoSlotGameHonor.vue?vue&type=script&lang=js&"
import style0 from "./CasinoSlotGameHonor.vue?vue&type=style&index=0&id=3d4d0c67&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d4d0c67",
  null
  
)

export default component.exports